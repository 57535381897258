<template>
    <div class="page-game__content">
        
        <instructions
            v-bind:taskNumber="this.taskData.task_number"
            v-bind:task="this.taskData.task"
            v-bind:taskDescription="this.taskData.task_description"
        />

        <div class="truck__container">
            <div v-for="(item, optionNumber) in options"
                 :key="optionNumber"
                 :class="selectedOption ? (selectedOption == optionNumber ? 'truck__wrapper' : 'truck__wrapper truck__wrapper--dark') : 'truck__wrapper'">
                <input
                    type="radio"
                    :id="`option${optionNumber}`"
                    :value="optionNumber"
                    :checked="selectedOption == optionNumber"
                    v-model="selectedOption"/>
                <label class="truck__item truck__item--left" :for="`option${optionNumber}`">
                    <span class="truck__item--text">{{ item }}</span>
                </label>
                <span class="truck__item--right"></span>
            </div>
        </div>
    </div>
</template>

<script>
import Instructions from './common/Instructions.vue';

export default {
    props: ['taskData'],
    
    data() {
        return {
            options: this.taskData.options,
            selectedOption: null,
            recordKey: this.taskData.record_key,
            selectedClass: ''
        }
    },

    beforeMount() {
        this.populateFieldsWithPreviousValues();
    },

    methods: {
        clearOtherInputs: function() {
            for (let optionNumber in this.options) {
                if (optionNumber == this.selectedOption) {
                    continue;
                }
                const answerInput = this.getAnswerInput(this.recordKey, optionNumber);
                answerInput.val('');
            }
        },

        populateFieldsWithPreviousValues: function() {
            for (let optionNumber in this.options) {
                const answerInput = this.getAnswerInput(this.recordKey, optionNumber);
                const inputValue = answerInput.val();

                if (inputValue) {
                    this.selectedOption = optionNumber;
                }
            }
        }
    },

    watch: {
        selectedOption(value) {
            this.clearOtherInputs();
            const fieldValue = 1;
            const answerInput = this.getAnswerInput(this.recordKey, this.selectedOption);
            
            /**
             * Not checking for an edge case of this.selectedOption
             * being null because if a null value is passed to the validation
             * it will fail, prompting the user to select an option again.
             */
            answerInput.val(fieldValue);
        }
    }
}
</script>