<template>
    <div id="instructions" class="page-game-instructions">
        <Task
            v-bind:taskNumber="taskNumber"
            v-bind:task="task"
            v-bind:taskExtraDescription="taskExtraDescription"
        />
        <TaskDescription v-bind:description="taskDescription"/>
    </div>
</template>

<script>
import Task from './Task.vue';
import TaskDescription from './TaskDescription.vue';

export default {
    components: {Task, TaskDescription},
    props: ['taskNumber', 'task', 'taskDescription', 'taskExtraDescription']
}
</script>
