<template>
    <div
        :class="`modal ${modalShow ? 'modal--active' : ''}`"
    >
        <div class="modal__overlay" @click="handleClick"></div>

        <div class="modal__content">
            <button class="modal__button" @click="handleClick">
                Close
                <span>
                    <svg class="icon-plus">
                        <use :xlink:href="baseUrl + '/images/icons/symbol-defs.svg#icon-plus'"></use>
                    </svg>
                </span>
            </button>
            <p class="modal__label">
                {{ modalLabel }}.
            </p>
            <img
                class="modal__image"
                :src="modalImageUrl"
                :alt="`Connection map ${modalLabel}`"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: ['modalShow', 'modalLabel', 'modalImageUrl', 'baseUrl'],

    methods: {
        handleClick: function() {
            this.$emit('close-modal');
        }
    }
}
</script>