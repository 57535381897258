<template>
    <div class="page-game__content">
        <instructions
            v-bind:taskNumber="taskData.task_number"
            v-bind:task="taskData.task"
            v-bind:taskDescription="taskData.task_description"
        />
        
        <div class="row">
            <div class="col-md-2 whiteboard__left">
                <draggable
                    class="drag__content whiteboard__left--inner"
                    group="options"
                    :list="unselectedOptions"
                    :move="handleMove">

                    <div
                        v-for="(item, fieldId) in unselectedOptions"
                        :key="fieldId"
                        :class="'option ' + (item !== null ? 'whiteboard__label whiteboard__label--small' : '')">
                        <span v-if="item !== null">{{ item.label }}</span>
                    </div>
                </draggable>
            </div>

            <div class="col-md-10 whiteboard__container">
                <div class="whiteboard__inner">
                    <table>
                        <tbody>
                            <tr>
                                <td class="whiteboard__step">Step 1</td>
                                <td><span class="whiteboard__sticky whiteboard__sticky--yellow">Brand objective</span></td>
                                <td>Increase value by $26m and increase penetration by 1pp</td>
                            </tr>
                            <tr>
                                <td class="whiteboard__step">Step 2</td>
                                <td><span class="whiteboard__sticky whiteboard__sticky--green">Comms objective</span></td>
                                <td>Be inspired that Silki is perfect for sharing and excited to try new Silki sharing bags</td>
                            </tr>
                            <tr>
                                <td class="whiteboard__step">Step 3a</td>
                                <td><span class="whiteboard__sticky whiteboard__sticky--pink">Brand Equity Success KPI</span></td>
                                <td>Situational Salience for sharing occasions</td>
                            </tr>
                            <tr>
                                <td class="whiteboard__step whiteboard__step--active">Step 3b</td>
                                <td><span class="whiteboard__sticky whiteboard__sticky--orange">Campaign success KPI</span></td>
                                <td>
                                    <div class="row">
                                        <post-it-note-dropzone
                                            dropzone-label="Campaign Success KPI 1"
                                            label-size="small"
                                            :list="selectedOptions[0]"
                                            :is-disabled="dropzoneShouldBeDisabled(selectedOptions[0])"
                                            :handle-change="handleChange"
                                            :handle-move="handleMove2"
                                        />

                                        <post-it-note-dropzone
                                            dropzone-label="Campaign Success KPI 2"
                                            label-size="small"
                                            :list="selectedOptions[1]"
                                            :is-disabled="dropzoneShouldBeDisabled(selectedOptions[1])"
                                            :handle-change="handleChange"
                                            :handle-move="handleMove2"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="whiteboard__step">Step 4</td>
                                <td><span class="whiteboard__sticky whiteboard__sticky--blue-pastel">Optimization metrics</span></td>
                                <td>Optimization metrics will be defined later in the TCP process</td>
                            </tr>                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Instructions from './common/Instructions.vue';
import PostItNoteDropzone from './question-components/PostItNoteDropzone.vue';

export default {
    components: {Instructions, PostItNoteDropzone},

    props: ['taskData'],

    data() {
        return {
            options: this.taskData.options,
            recordKey: this.taskData.record_key,
            unselectedOptions: [],
            selectedOptions: [
                [],
                [],
            ],
        }
    },

    methods: {
        // not allow moving from list to box if box has elelment
        handleMove: function(evt) {
            if (evt.relatedContext.list.length > 0) {
                return false;
            }
        },

        // not allow moving from box to box (list will have more than 1 elements in the list)
        handleMove2: function(evt) {
            if (evt.relatedContext.list.length <= 1) {
                return false;
            }
        },        

        handleChange: function(evt) {
            let fieldId = null;
            let answerInput = null
            
            if (evt.hasOwnProperty('added')) {
                fieldId = evt.added.element.id;
                answerInput = this.getAnswerInput(this.recordKey, fieldId);
                
                if (! answerInput.val()) {
                    let fieldValue = '';
                    // find out which kpi box
                    for (let i=0; i<this.selectedOptions.length; i++) {
                        if (this.selectedOptions[i] && this.selectedOptions[i][0] 
                            && !this.selectedOptions[i][0].processed) {
                            fieldValue = i + 1;
                            this.selectedOptions[i][0].processed = true;
                            break;
                        }
                    }
                    answerInput.val(fieldValue); // 1-kpi1, 2-kpi2
                }
            } else if (evt.hasOwnProperty('removed')) {
                fieldId = evt.removed.element.id;
                answerInput = this.getAnswerInput(this.recordKey, fieldId);
                answerInput.val('');
                evt.removed.element.processed = false;
            }
        },

        dropzoneShouldBeDisabled: function(optionList) {
            // only allow one item
            return (this.optionList && this.optionList.length > 0);
        },        

        setUpunselectedOptions: function() {
            for (let property in this.options) {
                let item = {
                    id: property,
                    label: this.options[property],
                };
                const answerInput = this.getAnswerInput(this.recordKey, property);
                if (answerInput.val()) {
                    let kpiIdx = parseInt(answerInput.val()) - 1;
                    item.processed = true;
                    this.selectedOptions[kpiIdx].push(item);
                } else {
                    this.unselectedOptions.push(item);
                }                
            }
        },
        
    },

    beforeMount() {
        this.setUpunselectedOptions();
    }
}
</script>