<template>
    <div class="page-game__content">
        
        <instructions
            :taskNumber="taskData.task_number"
            :task="taskData.task"
        />

        <div class="card">
            <div class="card__inner row">
                <div v-for="(item, index) in options"
                    :key="index" 
                    class="col-md-4"
                >
                    <label
                        :for="`option${index}`"
                        :class="'game-checkbox'"
                    >
                        <div class="card__left">
                            <span>{{ item.label }}</span>
                            <img :src="baseUrl + '/images/R4/illustration-' + item.label + '.svg'" :alt="'illustration ' + item.label" />
                        </div>

                        <div class="card__right">
                            <input
                                type="checkbox"
                                v-model="selectedOptions"
                                :value="index"
                                :id="`option${index}`"
                                :disabled="shouldBeDisabled(index)"
                                :checked="selectedOptions.indexOf(index) !== -1"
                                @change="handleChange(index)"
                            />

                            <div class="game-checkbox__item">{{ item.description }}</div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['taskData'],
    
    data() {
        return {
            options: this.taskData.options,
            recordKey: this.taskData.record_key,
            maxAmountOfSelections: this.taskData.max_amount_of_selections,
            selectedOptions: [],
            baseUrl: null
        }
    },

    methods: {
        shouldBeDisabled: function(fieldId) {
            return this.checkboxShouldBeDisabled(this.selectedOptions, this.maxAmountOfSelections, fieldId);
        },

        handleChange: function(fieldId) {
            this.onCheckboxChange(fieldId, this.recordKey, this.selectedOptions, this);
        },
    },

    beforeMount: function() {
        this.previousValues(this.options, this.recordKey, this.selectedOptions, this);
        this.baseUrl = window.appConfig.baseUrl;
    }
}
</script>