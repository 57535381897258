<template>
    <div :class="'option option-' + optionNumber">
        <div v-if="optionText" class="option-text">
            <span v-if="optionNumber" class="option-number">{{ optionNumber }}</span>
            <p>{{ optionText }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: ['optionNumber', 'optionText', 'optionQuestion']
}
</script>