<template>
    <div :class="'row option chocolate__bar chocolate__bar--' + item.label">
        <div class="col-md-3 chocolate__bar--left">
            <span class="chocolate__bar--letter">{{ item.label }}.</span>
        </div>

        <div class="col-md-offset-1 col-md-6 chocolate__bar--right">
            <div class="chocolate__bar--map">
                <img
                    :src="imageUrl(item.full)"
                    :alt="`Map thumbnail for item ${item.label}`"
                >
            </div>

            <button
                class="chocolate__bar--btn"
                @click="viewImage(item)"
            >
                View Map
                <span>
                    <svg class="icon-zoom-in">
                        <use :xlink:href="baseUrl + '/images/icons/symbol-defs.svg#icon-zoom-in'"></use>
                    </svg>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['item', 'imageUrl', 'baseUrl'],

    methods: {
        viewImage: function(item) {
            this.$emit('show-modal', {
                label: item.label,
                imageUrl: this.imageUrl(item.full)
            });
        }
    },
}
</script>