<template>
    <div class="page-game__content row">
        
        <instructions
            :taskNumber="taskData.task_number"
            :task="taskData.task"
            :taskDescription="taskData.task_description"
            class="col-md-12"
        />

        <div class="col-md-3">
            <gradual-fill
                :resource-left="moneyLeft"
                :prefix="currencySymbol"
                :suffix="'m'"
                :max="maxSpendingMoney"
            />
        </div>

        <div class="col-md-9 resource-spender__container">
            <resource-spender
                v-for="(item, index) in options"
                :key="index"
                :index="index"
                :description="item"
                :step="step"
                :max="maxSpendingMoney"
                :min="0"
                :prefix="currencySymbol"
                :suffix="'m'"
                :resource-left="moneyLeft"
                :record-key="recordKey"
                @range-change="handleRangeChange"
            />
        </div>
    </div>
</template>

<script>
import GradualFill from './question-components/GradualFill.vue';
import ResourceSpender from './question-components/ResourceSpender.vue';

export default {
    components: {GradualFill, ResourceSpender},
    props: ['taskData'],
    
    data() {
        return {
            options: this.taskData.options,
            step: this.taskData.step,
            recordKey: this.taskData.record_key,
            form: jQuery('.hidden-form'),
            maxSpendingMoney: this.taskData.max_spending_money,
            moneyLeft: this.oneDecimalPoint(this.taskData.max_spending_money),
            currencySymbol: this.taskData.currency_symbol
        }
    },

    methods: {
        handleRangeChange: function(payload) {
            const answerInput = payload.answerInput;
            const value = payload.value;
            const previousValue = payload.previousValue;
            const isTaking = payload.isTaking;

            if (isTaking && this.moneyLeft > 0) {
                let moneyTaken = this.oneDecimalPoint(value - previousValue);
                this.moneyLeft -= moneyTaken;
            }
            if (isTaking === false) {
                let givenBack = this.oneDecimalPoint(previousValue - value);
                this.moneyLeft += givenBack;
            }
            
            this.moneyLeft = this.oneDecimalPoint(this.moneyLeft);
            answerInput.val(value);
        }
    }
}
</script>