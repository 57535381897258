<template>
    <div class="page-game__content">
        <instructions
            v-bind:taskNumber="taskData.task_number"
            v-bind:task="taskData.task"
            v-bind:taskDescription="taskData.task_description"
        />
        
        <div class="row">
            <div class="col-md-2 whiteboard__left">
                <draggable
                    class="drag__content whiteboard__left--inner"
                    group="options"
                    :list="optionsList"
                    :move="handleMove">

                    <div
                        v-for="(item, fieldId) in optionsList"
                        :key="fieldId"
                        :class="'option ' + (item !== null ? 'whiteboard__label whiteboard__label--big' : '')">
                        <div v-if="item !== null">
                            <span>{{ item.label }}</span><br>
                            <span>{{ item.description }}</span>
                        </div>
                    </div>
                </draggable>
            </div>

            <div class="col-md-10 whiteboard__container">
                <div class="whiteboard__inner">

                    <div class="whiteboard__head">
                        <div class="whiteboard__sticky whiteboard__sticky--green">Comms objective</div>
                        <p class="whiteboard__head--text">Be inspired that Silki is perfect for sharing and excited to try new Silki sharing bags</p>
                    </div>

                    <div class="row">
                        <div class="col-md-3 col-lg-3 whiteboard__column">

                            <post-it-note-dropzone
                                dropzone-name="Problem"
                                label-size="big"
                                sticky-class="whiteboard__sticky--yellow"
                                :list="problemList"
                                :is-disabled="this.dropzoneShouldBeDisabled('problem')"
                                :handle-change="handleChange"
                            />

                            <post-it-note-dropzone
                                dropzone-name="Insight"
                                label-size="big"
                                sticky-class="whiteboard__sticky--yellow"
                                :list="insightList"
                                :is-disabled="this.dropzoneShouldBeDisabled('insight')"
                                :handle-change="handleChange"
                            />

                        </div>

                        <div class="col-md-4 col-lg-6 whiteboard__column center">
                            <div class="whiteboard__sticky whiteboard__sticky--uppercase whiteboard__sticky--yellow">Opportunity</div>

                            <div class="big-circle">
                                <div class="content-filler big-circle__content">
                                    <p class="content-filler__text">To be co-created with the agency at the briefing session</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3 col-md-offset-1 col-lg-3 col-lg-offset-0 whiteboard__column">
                            <post-it-note-dropzone
                                dropzone-name="Advantage"
                                label-size="big"
                                sticky-class="whiteboard__sticky--yellow"
                                :list="advantageList"
                                :is-disabled="this.dropzoneShouldBeDisabled('advantage')"
                                :handle-change="handleChange"
                            />

                            <div class="content-filler">
                                <div class="whiteboard__sticky whiteboard__sticky--yellow">Strategy</div>
                                <p class="content-filler__text">To be co-created with the agency at the briefing session</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Instructions from './common/Instructions.vue';
import PostItNoteDropzone from './question-components/PostItNoteDropzone.vue';

export default {
    components: {Instructions, PostItNoteDropzone},

    props: ['taskData'],

    data() {
        return {
            options: this.taskData.options,
            recordKey: this.taskData.record_key,
            choiceInThisQuestion: this.taskData.choice_in_this_question,
            optionsList: [],
            problemList: [],
            insightList: [],
            advantageList: [],
            alreadyGivenAnswers: this.taskData.already_given_answers
        }
    },

    methods: {
        handleMove: function(evt) {
            if (evt.relatedContext.list.length === 1) {
                return false;
            }
        },

        handleChange: function(evt) {
            let fieldId = null;
            let answerInput = null

            if (evt.hasOwnProperty('added')) {
                fieldId = evt.added.element.id;
                answerInput = this.getAnswerInput(this.recordKey, fieldId);
                
                if (! answerInput.val()) {
                    answerInput.val(fieldId);
                }

            } else if (evt.hasOwnProperty('removed')) {
                fieldId = evt.removed.element.id;
                answerInput = this.getAnswerInput(this.recordKey, fieldId);
                answerInput.val('');
            }
        },

        dropzoneShouldBeDisabled: function(checkFor) {
            return this.choiceInThisQuestion !== checkFor;
        },
        
        setUpOptionsList: function() {
            for (let property in this.options) {
                let item = this.options[property];
                this.optionsList.push({
                    id: property,
                    description: item.description,
                    label: item.label
                });
            }
        },

        populateWithPreviousAnswers: function() {
            if (this.alreadyGivenAnswers) {
                for (let listName in this.alreadyGivenAnswers) {
                    let answer = this.alreadyGivenAnswers[listName];

                    if (! answer) {
                        continue;
                    }

                    let answerInput = this.getAnswerInput(this.recordKey, answer.id);
                    let choiceInThisQuestionList = `${this.choiceInThisQuestion}List`;

                    if (listName == choiceInThisQuestionList && ! answerInput.val()) {
                        continue;
                    }

                    this[listName] = [answer];
                    
                    for (let index in this.optionsList) {
                        let option = this.optionsList[index];
                        
                        if (listName == choiceInThisQuestionList && option.id == answer.id) {
                            this.optionsList[index] = null;
                        }
                    }
                }
            }
        }
    },

    beforeMount() {
        this.setUpOptionsList();
        this.populateWithPreviousAnswers();
    }
}
</script>