<template>
    <div class="page-game__content">
        <instructions
            v-bind:taskNumber="taskData.task_number"
            v-bind:task="taskData.task"
            v-bind:taskDescription="taskData.task_description"
        />
        
        <div class="row">
            <div class="col-md-4 col-lg-5 whiteboard__left">
                <draggable
                    class="drag__content whiteboard__left--inner"
                    group="options"
                    :list="unselectedOptions"
                    :move="handleMove">

                    <div
                        v-for="(item, idx) in unselectedOptions"
                        :key="idx"
                        :class="'option ' + (item !== null ? 'whiteboard__label whiteboard__label--big' : '')">
                        <span class="whiteboard__label--title" v-if="item !== null">{{ item.label }}.</span>
                        <ul v-if="item && item.bundle">
                            <li v-for="(line, lidx) in item.bundle" :key="lidx"><span>{{ line }}</span></li>
                        </ul>
                    </div>
                </draggable>
            </div>

            <div class="col-md-8 col-lg-7 whiteboard__container">
                <div class="whiteboard__inner">
                    <div class="whiteboard__table--header row">
                        <div class="col-md-2"><span class="whiteboard__sticky whiteboard__sticky--green">Audience</span></div>
                        <div class="col-md-4"><span class="whiteboard__sticky whiteboard__sticky--pink">Comms tasks</span></div>
                        <div class="col-md-3"><span class="whiteboard__sticky whiteboard__sticky--yellow">Key interceptions</span></div>
                        <div class="col-md-3"><span class="whiteboard__sticky whiteboard__sticky--orange">Touchpoints</span></div>
                    </div>

                    <div class="whiteboard__table--row row" v-for="(row, idx) in taskData['table_rows']"  :key="idx">
                        <p class="col-md-2 whiteboard__table--label">{{ row['label'] }}</p>

                        <p class="col-md-4 whiteboard__table--desc">
                            <ul class="whiteboard__description--list">
                                <li
                                    v-for="(item, index) in splitDescriptionBundle(row['description'])"
                                    :key="index"
                                >
                                {{ item }}
                                </li>
                            </ul>
                        </p>

                        <post-it-note-dropzone
                            v-if="recordKey == 'r3_q2_a'"
                            label-size="big"
                            dropzoneClass='col-md-3'
                            description-split="&"
                            :list="selectedOptions[idx]"
                            :is-disabled="false"
                            :handle-change="handleChange"
                            :handle-move="handleMove2"
                        />
                        <post-it-note-dropzone
                            v-else
                            label-size="big"
                            dropzoneClass='col-md-3'
                            description-split="&"
                            :list="previousAnswers[idx]"
                            :is-disabled="true"
                            :handle-change="handleChange"
                            :handle-move="handleMove2"
                        />
                        <post-it-note-dropzone
                            v-if="recordKey == 'r3_q2_a'"
                            label-size="big"
                            dropzoneClass='col-md-3'
                            description-split="&"
                            :list="emptyAnswers[idx]"
                            :is-disabled="true"
                            :handle-change="handleChange"
                            :handle-move="handleMove2"
                        />
                        <post-it-note-dropzone
                            v-else
                            label-size="big"
                            dropzoneClass='col-md-3'
                            description-split="&"
                            :list="selectedOptions[idx]"
                            :is-disabled="false"
                            :handle-change="handleChange"
                            :handle-move="handleMove2"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Instructions from './common/Instructions.vue';
import PostItNoteDropzone from './question-components/PostItNoteDropzone.vue';

export default {
    components: {Instructions, PostItNoteDropzone},

    props: ['taskData'],

    data() {
        return {
            options: this.taskData.options,
            recordKey: this.taskData.record_key,
            unselectedOptions: [],
            selectedOptions: [[],[],[]],
            previousAnswers: [[],[],[]],
            emptyAnswers: [[],[],[]],
        }
    },

    methods: {
        // not allow moving from list to box if box has elelment
        handleMove: function(evt) {
            if (evt.relatedContext.list.length > 0) {
                return false;
            }
        },
        // not allow moving from box to box (list will have more than 1 elements in the list)
        handleMove2: function(evt) {
            if (evt.relatedContext.list.length <= 1) {
                return false;
            }
        },        
        handleChange: function(evt) {
            let fieldId = null;
            let answerInput = null
            
            if (evt.hasOwnProperty('added')) {
                fieldId = evt.added.element.id;
                answerInput = this.getAnswerInput(this.recordKey, fieldId);
                
                if (! answerInput.val()) {
                    let fieldValue = '';
                    // find out which slot box
                    for (let i=0; i<this.selectedOptions.length; i++) {
                        if (this.selectedOptions[i] && this.selectedOptions[i][0] 
                            && !this.selectedOptions[i][0].processed) {
                            fieldValue = i + 1;
                            this.selectedOptions[i][0].processed = true;
                            break;
                        }
                    }
                    answerInput.val(fieldValue); // slot 1-6
                }
            } else if (evt.hasOwnProperty('removed')) {
                fieldId = evt.removed.element.id;
                answerInput = this.getAnswerInput(this.recordKey, fieldId);
                answerInput.val('');
                evt.removed.element.processed = false;
            }
        },

        setUpunselectedOptions: function() {
            for (let property in this.options) {
                let item = {
                    id: property,
                    label: this.options[property].label,
                    description: this.options[property].description,
                    bundle: this.options[property].description.split("&"),
                };
                const answerInput = this.getAnswerInput(this.recordKey, property);
                if (answerInput.val()) {
                    let idx = parseInt(answerInput.val()) - 1;
                    item.processed = true;
                    this.selectedOptions[idx].push(item);
                } else {
                    this.unselectedOptions.push(item);
                }                
            }

            if (this.taskData.previous_answers ) {
                 for (let idx in this.taskData.previous_answers) {
                    this.previousAnswers[idx].push(this.taskData.previous_answers[idx]);
                 }
            }
        },
        
        splitDescriptionBundle: function(description) {
            let result = [];
            if (description) {
                result = description.split('&');
            }
            return result;
        },
    },

    beforeMount() {
        this.setUpunselectedOptions();
    }
}
</script>