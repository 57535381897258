<template>
    <div class="page-game__content">
        <instructions
            v-bind:taskNumber="this.taskData.task_number"
            v-bind:task="this.taskData.task"
            v-bind:taskDescription="this.taskData.task_description"
        />

        <div class="row">
            <div class="col-md-9 col-lg-8 shelf">
                <draggable
                    class="shelf__inner row"
                    group="options"
                    :list="optionsList"
                    :move="handleMove"
                >
                    <div
                        v-for="(item, fieldId) in optionsList"
                        :key="fieldId"
                        class="col-md-4 chocolate__bar--wrapper"
                    >
                        <chocolate-bar-draggable
                            v-if="item !== null"
                            :item="item"
                            :image-url="imageUrl"
                            :base-url="baseUrl"
                            @show-modal="handleShowModal"
                        />
                    </div>
                </draggable>
                </div>

            <div :class="`col-md-3 col-lg-4 basket ${selection.length ? 'basket--full' : ''}`">
                <p class="instructions__handwritten">Drag <u>one</u> Silki bar <br> into your basket</p>

                <div class="basket__inner">
                    <draggable
                        class="basket__dropzone"
                        group="options"
                        :list="selection"
                        @change="handleChange"
                    >
                        <chocolate-bar-draggable
                            v-if="selection.length"
                            :item="selection[0]"
                            :image-url="imageUrl"
                            :base-url="baseUrl"
                            @show-modal="handleShowModal"
                        />
                        <span></span>
                    </draggable>
                </div>
            </div>
        </div>

        <image-modal
            :modal-show="modalShow"
            :modal-label="modalLabel"
            :modal-image-url="modalImageUrl"
            :base-url="baseUrl"
            @close-modal="handleCloseModal"
        />
    </div>    
</template>

<script>
import draggable from "vuedraggable";
import ChocolateBarDraggable from "./question-components/ChocolateBarDraggable.vue";
import ImageModal from "./question-components/ImageModal.vue";

export default {
    props: ['taskData'],

    data() {
        return {
            options: this.taskData.options,
            recordKey: this.taskData.record_key,
            optionsList: [],
            selection: [],
            fileStorageLink: this.taskData.file_storage_link,
            modalShow: false,
            modalLabel: '',
            modalImageUrl: '',
            baseUrl: null
        }
    },

    methods: {
        handleMove: function(evt) {
            if (evt.relatedContext.list.length > 0) {
                return false;
            }
        },

        handleShowModal: function(evt) {
            this.modalLabel = evt.label;
            this.modalImageUrl = evt.imageUrl;
            this.modalShow = true;
        },

        handleCloseModal: function() {
            this.modalShow = false;
            this.modalLabel = '';
            this.modalImageUrl = '';
        },

        handleChange: function(evt) {
            const fieldValue = 1;
            let fieldId = null;
            let answerInput = null;

            if (evt.hasOwnProperty('added')) {
                fieldId = evt.added.element.id;
                answerInput = this.getAnswerInput(this.recordKey, fieldId);
                answerInput.val(fieldValue);
                this.selection.push(evt.added.element);
            }
            else if (evt.hasOwnProperty('removed')) {
                fieldId = evt.removed.element.id;
                answerInput = this.getAnswerInput(this.recordKey, fieldId);
                answerInput.val('');
                this.selection = [];
            }
        },

        imageUrl: function(fileName) {
            return `${this.fileStorageLink}/${fileName}`;
        },   

        setUpOptionsList: function() {
            for (let property in this.options) {
                let item = this.options[property];
                this.optionsList.push({
                    id: property,
                    thumbnail: item.thumbnail,
                    full: item.full,
                    label: item.label
                });
            }
        },

        populateFieldsWithPreviousValues: function() {
            for (let index in this.optionsList) {
                const item = this.optionsList[index];
                const answerInput = this.getAnswerInput(this.recordKey, item.id);

                if (answerInput.val()) {
                    this.selection.push(item);
                    break;
                }
            }

            if (this.selection.length) {
                const item = this.selection[0];
                this.optionsList[item.id - 1] = null;
            }
        }
    },

    beforeMount() {
        this.setUpOptionsList();
        this.populateFieldsWithPreviousValues();
        this.baseUrl = window.appConfig.baseUrl;
    }
}
</script>