/**
 * We'll load general libraries, such as lodash, jQuery, and moment
 */
 try {
    window._ = require('lodash');
} catch (e) {
    console.error(e);
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/**
 * We will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./vue-components', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/**
 * Sample code to register Vue components manually
 *
 * Vue.component('example-component', require('./components/ExampleComponent.vue').default);
 */

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// add the global event bus to avoid import in each components.
Vue.prototype.$eventBus = new Vue();

window.Vue.mixin({
    methods: {
        getAnswerInput: function(recordKey, index) {
            return jQuery(`[name="${recordKey}[${index}]"]`);
        },

        oneDecimalPoint: function(value) {
            return parseFloat(Math.round(value * 10) / 10);
        },

        handleRangeChange: function(payload, vm) {
            const answerInput = payload.answerInput;
            const value = payload.value;
            const previousValue = payload.previousValue;
            const isTaking = payload.isTaking;

            if (isTaking && vm.moneyLeft > 0) {
                let moneyTaken = vm.oneDecimalPoint(value - previousValue);
                vm.moneyLeft -= moneyTaken;
            }
            if (isTaking === false) {
                let givenBack = vm.oneDecimalPoint(previousValue - value);
                vm.moneyLeft += givenBack;
            }
            
            vm.moneyLeft = vm.oneDecimalPoint(vm.moneyLeft);
            answerInput.val(value);
        },

        seeInputValues: function(recordKey, optionsList, vm) {
            for (let fieldId in optionsList) {
                let answerInput = vm.getAnswerInput(recordKey, fieldId);
                console.log(answerInput.attr('name'), `, fieldId is ${fieldId} = ${answerInput.val()}`);
            }
        },

        checkboxShouldBeDisabled: function(selectedOptions, maxAmountOfSelections, value) {
            return (selectedOptions.length === maxAmountOfSelections) && selectedOptions.indexOf(value) === -1
        },

        onCheckboxChange: function(fieldId, recordKey, selectedOptions, vm) {
            const fieldValue = 1;
            const answerInput = vm.getAnswerInput(recordKey, fieldId);

            if (selectedOptions.indexOf(fieldId) !== -1) {
                answerInput.val(fieldValue);
            }
            else {
                answerInput.val('');
            }
        },
        
        previousValues: function(originalOptions, recordKey, optionsListToPopulate, vm) {
            for (let fieldId in originalOptions) {
                const answerInput = vm.getAnswerInput(recordKey, fieldId);

                if (answerInput.val()) {
                    optionsListToPopulate.push(fieldId);
                }
            }
        },

        clearInputs: function(recordKey, options, selectedOption, vm) {
            for (let fieldId in options) {
                if (fieldId == selectedOption) {
                    continue;
                }
                const answerInput = vm.getAnswerInput(recordKey, fieldId);
                answerInput.val('');
            }
        }
    }
})

const app = new Vue({
    el: '#app',
});