<template>
    <div class="page-game__content row">
        
        <instructions
            :taskNumber="taskData.task_number"
            :task="taskData.task"
            :taskDescription="taskData.task_description"
            class="col-md-12"
        />

        <div class="col-md-3">
            <gradual-fill
                :resource-left="moneyLeft"
                :prefix="currencySymbol"
                :suffix="'m'"
                :max="maxSpendingMoney"
                :record-key="recordKey"
            />
        </div>

        <div class="col-md-9 resource-spender__container">
            <div class="resource-spender__items">
                <resource-spender
                    v-for="(item, index) in options"
                    :key="index"
                    :index="index"
                    :description="item"
                    :step="step"
                    :max="maxSpendingMoney"
                    :min="0"
                    :prefix="currencySymbol"
                    :suffix="'m'"
                    :resource-left="moneyLeft"
                    :record-key="recordKey"
                    @range-change="rangeChange"
                />
            </div>

            <div class="row boxes">
                <div class="col-md-6">
                    <div class="calculate-roi__wrapper">
                        <div class="calculate-roi__inner">

                            <button
                                class="game-button game-button--primary"
                                @click="handleClick"
                                :disabled="roiDisable"
                            >
                                Calculate expected ROI
                            </button>

                            <div class="calculate-roi__display">
                                <span>
                                    {{currencySymbol}}{{ roi }} ROI
                                </span>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="boxes__red"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GradualFill from './question-components/GradualFill.vue';
import ResourceSpender from './question-components/ResourceSpender.vue';

export default {
    components: {GradualFill, ResourceSpender},
    props: ['taskData'],
    
    data() {
        return {
            options: this.taskData.options,
            step: this.taskData.step,
            recordKey: this.taskData.record_key,
            maxSpendingMoney: this.taskData.max_spending_money,
            moneyLeft: this.oneDecimalPoint(this.taskData.max_spending_money),
            currencySymbol: this.taskData.currency_symbol,
            roi: '0.0',
            roiTable: this.taskData.roi_table,
            roiDisable: true,
            selectedAmounts: []
        }
    },

    watch: {
        moneyLeft: function() {
            if(this.moneyLeft === 0) {
                this.roiDisable = false;
            } else {
                this.roiDisable = true;
            }
        }
    },

    methods: {
        rangeChange: function(payload) {
            this.handleRangeChange(payload, this);

            this.selectedAmounts[payload.fieldId] = payload.value;
        },

        handleClick: function() {
            let lookupString = '';

            for (let index in this.selectedAmounts) {
                let value = this.selectedAmounts[index];
                lookupString += `${value} `;
            }

            lookupString = lookupString.trim();

            let result = this.roiTable[lookupString];

            if (! result) {
                this.roi = '0.0';
                return;
            }

            this.roi = result;
        }
    },

    beforeMount() {
        for (let fieldId in this.options) {
            this.selectedAmounts[fieldId] = 0;
        }
    },

    mounted() {
        this.handleClick();
    }
}
</script>