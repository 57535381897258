<template>
    <div :class="`dropzone ${dropzoneClass ? dropzoneClass : ''}`">
        <div v-if="dropzoneName" :class="'whiteboard__sticky ' + stickyClass">{{ dropzoneName }}</div>
        <div :class="`whiteboard__dropzone  whiteboard__dropzone--${labelSize}`">
            <div v-if="!list.length" :class="`whiteboard__label--empty whiteboard__label--empty-${labelSize} ${isDisabled ? 'disabled' : ''}`">{{ dropzoneLabel ? dropzoneLabel : '' }}</div>
            <draggable
                :list="list"
                group="options"
                class="drop__item"
                :disabled="isDisabled"
                :move="handleMove"
                @change="handleChange"
            >
                <div v-if="list.length" :class="`option whiteboard__label whiteboard__label--${labelSize} ${isDisabled ? 'disabled' : ''}`">
                    <span :class="`${descriptionSplit ? 'whiteboard__label--title' : ''}`">{{ list[0].label }}{{ descriptionSplit ? '.' : ''}}</span><br>
                    <span v-if="!descriptionSplit">{{ list[0].description }}</span>
                    <ul v-else>
                        <li v-for="(line, lidx) in splitDescriptionBundle(list[0].description)" :key="lidx"><span>{{ line }}</span></li>
                    </ul>
                </div>
            </draggable>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
    components: {draggable},

    props: ['dropzoneName', 'dropzoneLabel', 'list', 'isDisabled', 'handleChange', 'handleMove', 'stickyClass', 'labelSize', 'descriptionSplit', 'dropzoneClass'],

    methods: {
        splitDescriptionBundle: function(description) {
            let result = [];
            if (description && this.descriptionSplit) {
                result = description.split(this.descriptionSplit);
            }
            return result;
        },
    }
}
</script>