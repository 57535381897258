<template>
    <div class="page-game__content row">
        
        <instructions
            :taskNumber="taskData.task_number"
            :task="taskData.task"
            :taskDescription="taskData.task_description"
            class="col-md-12"
        />

        <div class="tv__wrapper row">
            
            <div
                v-for="(item, fieldId) in options"
                :key="fieldId"
                :class="`col-md-4 tv__column ${selectedOption ? (selectedOption == fieldId ? '' : 'tv--inactive') : ''}`"
            >
                <h2>{{ item.label }}</h2>

                <input
                    type="radio"
                    v-model="selectedOption"
                    :id="`option${fieldId}`"
                    name="option"
                    :value="fieldId"
                />

                <label
                    :for="`option${fieldId}`"
                    :class="`tv__item tv__item--${fieldId}`"
                >
                    <div class="tv__text">
                        <h3>{{ item.title }}</h3>
                        <p>{{ item.description }}</p>
                    </div>
                </label>
            </div>

        </div>

    </div>
</template>

<script>

export default {
    props: ['taskData'],
    
    data() {
        return {
            options: this.taskData.options,
            recordKey: this.taskData.record_key,
            selectedOption: false
        }
    },

    methods: {
        populateFieldsWithPreviousValues: function() {
            for (let optionNumber in this.options) {
                const answerInput = this.getAnswerInput(this.recordKey, optionNumber);
                const inputValue = answerInput.val();

                if (inputValue) {
                    this.selectedOption = optionNumber;
                }
            }
        }
    },
    
    watch: {
        selectedOption: function(fieldId) {
            this.clearInputs(this.recordKey, this.options, this.selectedOption, this);
            const answerInput = this.getAnswerInput(this.recordKey, fieldId);
            answerInput.val(fieldId);
        }
    },

    beforeMount() {
        this.populateFieldsWithPreviousValues();
    }
}
</script>