<template>
    <div class="resource-spender">
        <div class="resource-spender__label" v-html="description"></div>

        <div class="resource-spender__buttons">
            <button
                class="subtract game-button--minus"
                @click="handleClick(false)"
            ></button>
            <button
                class="add game-button--plus"
                @click="handleClick(true)"
            ></button>
        </div>

        <div class="resource-spender__input">
            <span v-if="prefix" class="prefix">{{ prefix }}</span>
            <input v-model="value" type="number" @change="inputChange"/>
            <span v-if="suffix" class="suffix">{{ suffix }}</span>
        </div>

        <div class="resource-spender__sack">
            <div class="resource-spender__fill">
                <div v-if="recordKey !== 'r3_q1'" class="resource-spender__filled" :style="'height:' + filledHeight + '%'"></div>
                <div v-else class="resource-spender__filled">
                    <div class="gradual__filled--chocolates" v-for="index in allocatedChocolateBlocks" :key="index"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['description', 'index', 'step', 'max', 'min', 'prefix', 'suffix', 'resourceLeft', 'recordKey'],

    data() {
        return {
            value: this.getAnswerInput(this.recordKey, this.index).val() ? this.getAnswerInput(this.recordKey, this.index).val() : 0,
            previousValue: 0,
            isTaking: null,
            interval: null,
            filledHeight: '0',
            chocolateBlocks: 6,
            allocatedChocolateBlocks: null,
        }
    },
    watch: {
        value: function() {
            this.filledHeight = (this.value/this.max)*100;
            this.allocatedChocolateBlocks = this.value/5;
            this.handleChange();
        }
    },
    methods: {
        inputChange: function() {
            if (this.value === '') {
                this.value = 0;
            }
        },

        handleClick: function(adding) {
            if (adding) {
                this.add();
            }
            else {
                this.subtract();
            }
            this.handleChange();
        },

        handleMouseDown: function(adding) {
            const vm = this;
            const intervalTime = 100;

            if (adding) {
                if (this.interval === null) {
                    this.interval = setInterval(function() {
                        vm.arithmetic('+', true);
                        vm.handleChange();
                    }, intervalTime);
                }
            }
            else {
                if (this.interval === null) {
                    this.interval = setInterval(function() {
                        vm.arithmetic('-', true);
                        vm.handleChange();
                    }, intervalTime);
                }
            }
        },

        handleMouseUp: function() {
            if (this.interval !== null) {
                clearInterval(this.interval);
                this.interval = null;
            }
        },

        arithmetic: function(operation, wholeNumbers = false) {
            let value = this.step;

            if (wholeNumbers) {
                value = 1;
            }

            if (operation == '+') {
                this.add(value);
                return;
            }
            this.subtract(value);
        },

        add: function(summand = this.step) {
            this.value = this.oneDecimalPoint(parseFloat(this.value) + parseFloat(summand));
        },

        subtract: function(summand = this.step) {
            this.value = this.oneDecimalPoint(parseFloat(this.value) - parseFloat(summand));
        },

        handleChange: function() {
            if (this.value < 0) {
                this.value = 0;
            }
            if (this.value > this.previousValue) {
                if (this.resourceLeft == 0) {
                    this.value = this.oneDecimalPoint(this.previousValue);
                }
                else {
                    this.isTaking = true;
    
                    if ((this.value - this.previousValue) > this.resourceLeft) {
                        this.value = this.oneDecimalPoint(this.previousValue + this.resourceLeft);
                    }
                }
            }
            else if (this.value < this.previousValue) {
                this.isTaking = false;
            }
            else {
                this.isTaking = null;
            }

            this.$emit('range-change', {
                'answerInput': this.getAnswerInput(this.recordKey, this.index),
                'value': this.oneDecimalPoint(this.value),
                'previousValue': this.oneDecimalPoint(this.previousValue),
                'isTaking': this.isTaking,
                'fieldId': this.index
            });

            this.previousValue = this.oneDecimalPoint(this.value);
        }
    },
    mounted() {
        this.handleChange();
        this.filledHeight = (this.value/this.max)*100;
        this.allocatedChocolateBlocks = this.value/5;
    },
}
</script>