<template>
    <div class="page-game__content">
        <instructions
            v-bind:taskNumber="taskData.task_number"
            v-bind:task="taskData.task"
            v-bind:taskDescription="taskData.task_description"
        />

        <div class="drawer">
            <div class="drawer__inner row">
                <div
                    v-for="(item, fieldId) in options"
                    :key="fieldId"
                    @mouseover="mouseOver(fieldId)"
                    @mouseleave="hoverStarted = false"
                    :class="`col-md-4 ${hoverStarted ? (fieldId == hoverId ? '' : 'inactive') : ''}`">
                    <label
                        :for="`option${fieldId}`"
                        class="game-checkbox">
                        
                        <input
                            type="checkbox"
                            v-model="selectedOptions"
                            :value="fieldId"
                            :id="`option${fieldId}`"
                            :disabled="shouldBeDisabled(fieldId)"
                            :checked="selectedOptions.indexOf(fieldId) !== -1"
                            @change="handleChange(fieldId)"
                        />
                        <div class="game-checkbox__item">
                            <div class="game-checkbox__content">
                                <strong>{{ item.label }}</strong>
                                <p>{{ item.description }}</p>
                            </div>
                        </div>
                        <img :src="baseUrl + '/images/R4/chocolate-bars/' + item.label + 'png'" :alt="'Chocolate bar ' + item.label" />
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['taskData'],

    data() {
        return {
            options: this.taskData.options,
            recordKey: this.taskData.record_key,
            maxAmountOfSelections: this.taskData.max_amount_of_selections,
            selectedOptions: [],
            baseUrl: null,
            hoverStarted: false,
            hoverId: null
        }
    },

    methods: {
        shouldBeDisabled: function(fieldId) {
            return this.checkboxShouldBeDisabled(this.selectedOptions, this.maxAmountOfSelections, fieldId);
        },

        handleChange: function(fieldId) {
            this.onCheckboxChange(fieldId, this.recordKey, this.selectedOptions, this);
        },
        mouseOver: function(fieldId) {
            this.hoverStarted = true;
            this.hoverId = fieldId;
        },
    },

    beforeMount: function() {
        this.previousValues(this.options, this.recordKey, this.selectedOptions, this);
        this.baseUrl = window.appConfig.baseUrl;
    },
}
</script>