<template>
    <div class="page-game__content">
        <instructions
            v-bind:taskNumber="this.taskData.task_number"
            v-bind:task="this.taskData.task"
            v-bind:taskDescription="this.taskData.task_description"
        />

        <div class="row">
            <div class="drag col-md-8">
                <draggable
                    class="drag__content row"
                    group="options"
                    :list="optionsList"
                    :move="handleMove"
                >
                    
                    <div
                        v-for="(item, fieldId) in optionsList"
                        :key="fieldId"
                        :class="(item == null ? 'drag__null' : '')"
                    >
                        <div
                            v-if="item !== null"
                            class="option"
                        >
                            <strong>{{ item.label }}</strong>
                            <p>{{ item.description }}</p>
                            <img :src="baseUrl + '/images/R4/chocolate-blocks/' + item.label + 'svg'" :alt="'chocolate block ' + item.label" />
                        </div>
                    </div>

                </draggable>
            </div>

            <div class="drop col-md-4">
                <draggable
                    class="drop__content"
                    group="options"
                    :list="selection"
                    @change="handleChange"
                >
                    <div
                        v-for="(item, index) in selection"
                        :key="index"
                        class="drop__wrapper"
                    >
                        <div class="option">
                            <strong>{{ item.label }}</strong>
                            <p>{{ item.description }}</p>
                            <img :src="baseUrl + '/images/R4/chocolate-blocks/' + item.label + 'svg'" :alt="'chocolate block ' + item.label" />
                        </div>
                    </div>
                </draggable>
            </div>
        </div>
    </div>    
</template>

<script>
import draggable from "vuedraggable";

export default {
    props: ['taskData'],

    data() {
        return {
            options: this.taskData.options,
            recordKey: this.taskData.record_key,
            optionsList: [],
            selection: [],
            maxAmountOfSelections: this.taskData.max_amount_of_selections,
            baseUrl: null
        }
    },

    methods: {
        handleMove: function(evt) {
            if (evt.relatedContext.list.length >= this.maxAmountOfSelections) {
                return false;
            }
        },

        handleChange: function(evt) {
            const fieldValue = 1;
            let fieldId = null;
            let answerInput = null;

            if (evt.hasOwnProperty('added')) {
                fieldId = evt.added.element.id;
                answerInput = this.getAnswerInput(this.recordKey, fieldId);
                answerInput.val(fieldValue);
            }
            else if (evt.hasOwnProperty('removed')) {
                fieldId = evt.removed.element.id;
                answerInput = this.getAnswerInput(this.recordKey, fieldId);
                answerInput.val('');
            }
        }, 

        setUpOptionsList: function() {
            for (let property in this.options) {
                let item = this.options[property];
                this.optionsList.push({
                    id: property,
                    label: item.label,
                    description: item.description
                });
            }
        },

        populateFieldsWithPreviousValues: function() {
            for (let index in this.optionsList) {
                const item = this.optionsList[index];
                const answerInput = this.getAnswerInput(this.recordKey, item.id);

                if (answerInput.val()) {
                    this.selection.push(item);
                }
            }

            if (this.selection.length) {
                for (let index in this.selection) {
                    const item = this.selection[index];
                    this.optionsList[item.id - 1] = null;
                }
            }
        }
    },

    beforeMount() {
        this.setUpOptionsList();
        this.populateFieldsWithPreviousValues();
        this.baseUrl = window.appConfig.baseUrl;
    }
}
</script>