<template>
    <div class="page-game__content">
        
        <instructions
            :taskNumber="taskData.task_number"
            :task="taskData.task"
            :taskDescription="taskData.task_description"/>
        
        <div :class="'page-game-' + question + '__content'">
            <div class="drag">
                <span v-if="question === 'r1-q2'" class="drag__nav prev" @click="slider()"></span>
                <div  v-if="question === 'r1-q3'" class="drag__background"></div>
                <div class="drag__container">
                    <draggable
                        class="drag__content"
                        group="options"
                        :style="question === 'r1-q2' ? 'left: -' + sliderContentPos + 'px' : ''"
                        :list="optionsList"
                        :move="handleMove">
                        <div v-for="(item, index) in optionsList"
                            :key="index"
                            class="drag__wrapper">
                            <div v-if="item !== null"
                                :class="'drag__item drag__item-' + item.id"
                                :style="question === 'r1-q2' ? 'width: ' + sliderItemWidth + 'px' : ''">
                                <option-tag
                                    :option-number="item.id"
                                    :option-text="item.text"/>
                            </div>
                        </div>
                    </draggable>
                </div>
                <span v-if="question === 'r1-q2'" class="drag__nav next" @click="slider('next')"></span>
            </div>

            <div class="drop">
                <div v-for="index in maxNumberOfSelections"
                    :key="index"
                    class="drop__wrapper">
                    <div v-if="question === 'r1-q3' && labels" class="drop__text">
                        <strong>{{ showIndex ? index + '.' : '' }} {{ labels[index-1].label }}</strong>
                        <p>{{ labels[index-1].description }}</p>
                    </div>
                    <draggable
                        :list="selections[index]"
                        group="options"
                        :move="handleMove"
                        @change="evt => handleChange(evt, index)"
                        class="drop__item">
                        <option-tag
                            v-if="selections[index].length"
                            :option-number="selections[index][0].id"
                            :option-text="selections[index][0].text"/>
                    </draggable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import OptionTag from './OptionTag.vue'

export default {
    props: ['taskData', 'showIndex'],

    components: {
        draggable,
        OptionTag
    },

    data() {
        return {
            question: this.taskData.question,
            options: this.taskData.options,
            maxNumberOfSelections: this.taskData.max_amount_of_selections,
            optionsList: [],
            recordKey: this.taskData.record_key,
            selections: {},
            previouslySelected: [],
            sliderWidth: '',
            sliderMaxWidth: '',
            sliderItemWidth: '',
            sliderContentPos: 0,
            labels: []
        }
    },

    methods: {
        handleMove: function(evt) {
            if (evt.relatedContext.list.length === 1) {
                return false;
            }
        },

        handleChange: function(evt, dropzoneId) {
            let fieldId = null;
            let answerInput = null
            
            if (this.question == 'r1-q3') {
                fieldId = dropzoneId;
            }

            if (evt.hasOwnProperty('added')) {
                if (fieldId === null) {
                    fieldId = evt.added.element.id;
                }

                answerInput = this.getAnswerInput(this.recordKey, fieldId);

                if (this.question == 'r1-q3') {
                    answerInput.val(evt.added.element.id);
                }

                this.selections[dropzoneId] = [evt.added.element];

            }
            else if (evt.hasOwnProperty('removed')) {
                if (fieldId === null) {
                    fieldId = evt.removed.element.id;
                }

                answerInput = this.getAnswerInput(this.recordKey, fieldId);
                answerInput.val('');

                this.selections[dropzoneId] = [];
            }

            if (this.question !== 'r1-q3') {
                for (let index in this.selections) {
                    let selection = this.selections[index];

                    if (! selection.length) {
                        continue;
                    }

                    answerInput = this.getAnswerInput(this.recordKey, selection[0].id);
                    answerInput.val(index);
                }
            }

            this.$forceUpdate();
        },

        setUpOptionsList: function() {
            for (let property in this.options) {
                let item = this.options[property];
                this.optionsList.push({id: property, text: item});
            }
        },

        setUpSelections: function() {
            /**
             * The selections use a one-based index in order to be able to
             * populate the appropriate dropzones with previously selected
             * items.
             * Since answer inputs wil be populated with the index of a dropzone
             * that they've been placed into having a zero-based index could mean
             * that an option placed into the first dropzone (i.e. dropzone index
             * 0) would not be counted because the '0' will get
             * ignored when tallying up scores.
             */
            for (let index = 1; index < this.maxNumberOfSelections + 1; index++) {
                this.selections[index] = [];
            }
        },

        prePopulateR1Q3: function() {
            for (let index = 1; index < this.maxNumberOfSelections + 1; index++) {
                let answerInput = this.getAnswerInput(this.recordKey, index);
                let inputValue = answerInput.val();

                if (inputValue) {
                    this.selections[index].push(this.optionsList[inputValue - 1]);
                    this.previouslySelected.push(inputValue - 1);
                }
            }
        },

        prePopulateR1Q2: function() {
            for (let index in this.optionsList) {
                let item = this.optionsList[index];
                let answerInput = this.getAnswerInput(this.recordKey, item.id);
                let inputValue = answerInput.val();

                if (inputValue) {
                    this.selections[inputValue].push(item);
                    this.previouslySelected.push(index);
                }
            }
        },

        populateFieldsWithPreviousValues: function() {
            if (this.question == 'r1-q3') {
                this.prePopulateR1Q3();
            }
            else {
                this.prePopulateR1Q2();
            }
            
            for (let index of this.previouslySelected) {
                this.optionsList[index] = null;
            }
        },

        slider: function(pos) {
            if(pos === 'next') {
                this.sliderContentPos += this.sliderWidth;
                if(this.sliderContentPos >= this.sliderMaxWidth) {
                    this.sliderContentPos = 0
                }
            } else {
                if(this.sliderContentPos === 0) {
                    this.sliderContentPos = this.sliderMaxWidth - this.sliderWidth;
                } else {
                    this.sliderContentPos -= this.sliderWidth;
                }
            }
        }
    },

    beforeMount() {
        this.setUpOptionsList();
        this.setUpSelections();
        this.populateFieldsWithPreviousValues();

        if(this.question === 'r1-q3') {
            for (let property in this.taskData.labels) {
                let item = this.taskData.labels[property];
                this.labels.push({label: item.label, description: item.description});
            }
        }
    },
    mounted() {
        if(this.question === 'r1-q2') {
            this.sliderWidth = $('.drag__container').width();
            this.sliderMaxWidth = $('.drag__content').width();
            this.sliderItemWidth = this.sliderMaxWidth/20;
        }
    }
}
</script>