<template>
    <div class="page-game__content row">
        <instructions
            v-bind:taskNumber="this.taskData.task_number"
            v-bind:task="this.taskData.task"
            v-bind:taskDescription="this.taskData.task_description"
        />

        <div class="clipboard-wrapper">
            <div class="clipboard">
                <div class="clipboard-content">
                    <div class="clipboard-content__head">
                        <p class="clipboard-content__head__title">{{ taskData.description_heading }}</p>
                        <p class="clipboard-content__head__desc">
                            <strong>{{ taskData.description }}</strong>
                        </p>
                    </div>

                    <div class="options-wrapper">
                        <p class="options-wrapper__label"><strong>Select up to 3 ingredients</strong></p>

                        <div class="options">
                            <label
                                v-for="(item, index) in options"
                                :key="index"
                                :for="`option${index}`"
                                :class="'game-checkbox ' + getOptionActiveClass(index)"
                            >
                                <input
                                    type="checkbox"
                                    v-model="selectedOptions"
                                    :value="index"
                                    :id="`option${index}`"
                                    :disabled="shouldBeDisabled(index)"
                                    :checked="selectedOptions.indexOf(index) !== -1"
                                    @change="handleChange(index)"
                                >
                                <span class="game-checkbox__item">
                                    {{ trimedOptions[index - 1] }}
                                    <small class="">{{ optSubLineArray[index - 1] }}</small>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- You should be able to use this.fillHeight to fill up -->
        <!-- the vat of chocolate. It will only go up to a max of 99, -->
        <!-- this is so that we don't have to deal with floats and rounding -->
        <!-- up to 100. -->
        <!-- So this means that the chocolate would only fill up to -->
        <!-- 99% height of the choc vat container element. -->
        <!-- I think it should be possible to hide the sliver of unfilled -->
        <!-- height behind the top of the chocolate vat. -->
        <!-- Please let me know if this needs changing though. -->
        <div class="chocolate-vat fill-up"></div>
        <div class="chocolate-vat chocolate-vat--no-shadow fill-up" :class="getFillClass()"></div>
    </div>
</template>

<script>
import Instructions from './common/Instructions.vue';
import GradualFill from './question-components/GradualFill.vue';

export default {
    components: {Instructions, GradualFill},

    props: ['taskData'],

    data() {
        return {
            options: this.taskData.options,
            maxAmountOfSelections: this.taskData.max_amount_of_selections,
            recordKey: this.taskData.record_key,
            valuePerOption: 33,
            fillHeight: 0,
            selectedOptions: [],
            optSubLineArray: [],
            trimedOptions: [],
        }
    },

    beforeMount() {
        this.populateFieldsWithPreviousValues();
        
        Object.values(this.options).forEach(opt => {
            var subline = opt.substring(
                opt.lastIndexOf("(") + 1, 
                opt.lastIndexOf(")")
            );
            opt = opt.replace(/ *\([^)]*\) */g, "");
            this.trimedOptions.push(opt);
            this.optSubLineArray.push(subline);
        });

        switch(this.selectedOptions.length) {
            case 1:
                return this.fillHeight = 33;
            case 2:
                return this.fillHeight = 66;
            case 3:
                return this.fillHeight = 99;
            default:
                return '';
        }
    },
    
    methods: {
        handleChange: function(fieldId) {
            const fieldValue = 1;
            const answerInput = this.getAnswerInput(this.recordKey, fieldId);

            if (this.selectedOptions.indexOf(fieldId) !== -1) {
                answerInput.val(fieldValue);
                this.fillHeight += this.valuePerOption;
            }
            else {
                answerInput.val('');
                this.fillHeight -= this.valuePerOption;
            }
        },

        getFillClass() {
            switch(this.fillHeight) {
                case 0:
                    return '';
                case 33:
                    return 'fill-up--low';
                case 66:
                    return 'fill-up--medium';
                case 99:
                    return 'fill-up--max';
            }
        },

        populateFieldsWithPreviousValues: function() {
            for (let fieldId in this.options) {
                const answerInput = this.getAnswerInput(this.recordKey, fieldId);

                if (answerInput.val()) {
                    this.selectedOptions.push(fieldId);
                }
            }
        },

        getOptionActiveClass(index) {
            return this.selectedOptions.filter(el => el == index).length > 0
            ? 'game-checkbox--active'
            : '';
        },

        shouldBeDisabled: function(index) {
            return (this.selectedOptions.length === this.maxAmountOfSelections) && this.selectedOptions.indexOf(index) === -1
        }
    }
}
</script>