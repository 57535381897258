<template>
    <p class="page-game-instructions__task">
        <strong>{{ taskNumber }}.</strong> 
        <span v-html="task"></span>
        <span
            v-if="taskExtraDescription"
        >
            <br><span v-html="taskExtraDescription"></span>
        </span>
    </p>
</template>

<script>
export default {
    props: ['taskNumber', 'task', 'taskExtraDescription']
}
</script>
