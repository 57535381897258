<template>
    <div class="gradual__wrapper">
        <div class="gradual__fill">
            <div class="gradual__filled" :style="'height:' + filledHeight + '%'">
                <div v-if="recordKey !== 'r3_q1'" class="gradual__filled--shadow" :style="filledHeight == 0 ? 'opacity:0' : ''"></div>
                <div v-else class="gradual__filled--chocolates" v-for="index in chocolateBlocks" :key="index"></div>
            </div>
        </div>
        <div class="gradual__left">
            <span v-if="prefix" class="prefix">{{ prefix }}</span>
            {{ resourceLeft }}
            <span v-if="suffix" class="suffix">{{ suffix }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['resourceLeft', 'prefix', 'suffix', 'max', 'recordKey'],
    data() {
        return {
            filledHeight: '100',
            chocolateBlocks: 6,
        }
    },
    watch: {
        resourceLeft: function() {
            if(this.recordKey !== 'r3_q1') {
                this.filledHeight = (this.resourceLeft/this.max)*100;
            } else {
                this.chocolateBlocks = this.resourceLeft/5;
            }
        }
    },
}
</script>